// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `HOME | Brandon`,
    defaultDescription: `Brandon is a Brand Engagement, Design and Fit out company developing commercial projects.`,
  },
  zh: {
    path: `zh`,
    locale: `zh-CN`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `zh`,
    ogLanguage: `zh_CN`,
    defaultTitle: `首页 | Brandon`,
    defaultDescription: `上海牌顺品牌管理有限公司是一家专门从事商业品牌参与、设计和装修的公司。`,
  },
}
