import React from 'react'
import useTranslations from '../useTranslations'


const Footer = () => {
    const {
        footer,
        disclamer,
    } = useTranslations()

    return (
        <footer className='md:sticky w-full bottom-0 bg-black text-gray-200 z-20'>
            <div className='container px-2 mx-auto max-w-4xl sm:flex text-center justify-between text-xs py-2'>
                <div>{footer}</div>
                <div>{disclamer}</div>
            </div>
        </footer>
    )
}

export default Footer
