import React from 'react'
import LocalizedLink from '../../LocalizedLink'
import useMenu from '../../useMenu'


const MenuMobile = ({ handleClick, isActive }) => {
    const menuItems = useMenu()
    return (
        <div className="block sm:hidden">
            <div>
                <button onClick={handleClick} className='w-10 h-10 relative focus:outline-none'>
                    <span className='sr-only'>Open main menu</span>
                    <div className='block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2'>
                        <span aria-hidden='true'
                              className={`block absolute h-0.5 w-6 bg-current transform transition duration-500 ease-in-out  ${isActive?'rotate-45':'-translate-y-2'}`}></span>
                        <span aria-hidden='true'
                              className={`block absolute  h-0.5 w-6 bg-current transform transition duration-500 ease-in-out ${isActive?'opacity-0':''}`}></span>
                        <span aria-hidden='true'
                              className={`block absolute  h-0.5 w-6 bg-current transform  transition duration-500 ease-in-out  ${isActive?'-rotate-45':'translate-y-2'}`}></span>
                    </div>
                </button>
            </div>
            <nav className={`uppercase fixed bg-header-active z-50
                        transform transition duration-500 ease-in-out
                        top-16 bottom-0 w-full left-0
                        flex flex-col align-middle justify-center items-center
                        ${isActive?'translate-x-0':'translate-x-full'}
                        `}>
                {menuItems.map((menu, index) => (
                    <LocalizedLink
                        onClick={handleClick}
                        className={`border-b w-48 text-center border-white py-4 font-bold
                                border-none`}
                        to={menu.link}
                        aria-label={menu.name}
                        activeClassName='text-white'
                        key={`${menu.link}${index}`}
                    >
                        {menu.name}
                    </LocalizedLink>
                ))}
            </nav>
        </div>
    )
}

export default MenuMobile
