import React from 'react'
import LocalizedLink from '../LocalizedLink'
import { useLocale } from '../../hooks/locale'

const Logo = () => {
    const { locale } = useLocale();
    return (
        <LocalizedLink to={`/${locale==='en'?'':locale}`}>
        <svg className='w-40 z-40' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 368.9 80'>
            <path
                d='M368.9,64.69c-1.44,0-2.88-.09-4.31,0-1,.06-1.37-.21-1.36-1.3.07-4.67,0-9.34,0-14,0-4,.13-8.07-.12-12.1a19.61,19.61,0,0,0-1.88-7.61c-2-3.94-5.27-5.91-9.5-6.66-4.94-.86-9.88-.39-14.82-.11-2,.12-4,.48-6,.58-1.12.05-1.37.48-1.37,1.53,0,12.73,0,25.47.06,38.2,0,1.25-.35,1.59-1.53,1.48a21.9,21.9,0,0,0-3.24,0c-.7,0-.94-.23-.86-.89a5.77,5.77,0,0,0,0-.72q0-21.43,0-42.88c0-1.22.23-1.66,1.54-1.81a201.32,201.32,0,0,1,21.25-1.59,31.88,31.88,0,0,1,8.53,1.08c7,1.87,10.89,6.73,12.58,13.55.45,1.8.66,3.66,1,5.49Z'
                fill='#6b6c70' />
            <path
                d='M114.59,35.71c3.71.22,7.42.4,11.12.67,1,.08,1.27-.22,1.14-1.17a35.19,35.19,0,0,0-.51-3.67c-1.31-5.08-4.9-7.54-9.77-8.5-4.61-.91-9.24-.55-13.85-.14-3.54.32-7.06.83-10.58,1.3-.72.09-1-.05-1.07-.81-.07-1.35-.19-2.7-.34-4.05-.07-.63.19-.79.77-.9A124.88,124.88,0,0,1,105.56,17a51.48,51.48,0,0,1,10.16,0c5.32.73,10.07,2.56,13.43,7,2.73,3.61,3.39,7.85,3.43,12.22,0,5.35,0,10.7,0,16.05a19.49,19.49,0,0,0,.31,4.17c.51,2.25,1.71,3.28,4,3.23.79,0,.94.27.95.93,0,1.28.1,2.56.21,3.83,0,.63-.16.88-.78.89-3.88,0-7.09-.63-9.22-5.21-.17-.36-.21-1.1-.9-.38-3.25,3.39-7.4,4.93-11.92,5.56a48.63,48.63,0,0,1-16.8-.38c-6.76-1.44-10.41-5.82-10.64-12.69a23.45,23.45,0,0,1,.53-6.89c1.23-4.58,4.29-7.35,8.72-8.72a31.15,31.15,0,0,1,9.36-1.16h8.14Zm-1.87,5.61v0c-.28,0-.56-.06-.84-.07-4.07-.16-8.15-.55-12.16.57a7.76,7.76,0,0,0-6,6.41,15.28,15.28,0,0,0-.16,3.1c.14,4.49,2.18,7.1,6.58,8a42.44,42.44,0,0,0,16.54-.15,17,17,0,0,0,9.45-5.05,2.48,2.48,0,0,0,.79-1.89c-.05-2.92-.1-5.83,0-8.74,0-1.25-.38-1.56-1.5-1.61C121.18,41.78,117,41.54,112.72,41.32Z'
                fill='#6b6c70' />
            <path
                d='M249.06,32q0,15.39,0,30.77c0,.72,0,1.28-1,1.39-6.94.82-13.89,1.5-20.88,1.65a32.15,32.15,0,0,1-10.63-1.42c-6.32-2-9.84-6.65-11.27-12.88a39.62,39.62,0,0,1,.56-20.92c2.57-8.2,8.35-12.62,16.88-13.59,5.19-.59,10.27.25,15.38.88,1.42.18,2.85.39,4.24.71.91.21,1-.15,1-.89,0-5.35,0-10.69,0-16,0-1.09.28-1.47,1.39-1.43a22,22,0,0,0,3.34-.2c.79-.08,1,.17,1,1-.05,3.31,0,6.62,0,9.93ZM243.4,41.8V25.52c0-.61.07-1.14-.81-1.29-5.72-1-11.45-1.9-17.26-1.65-7.05.3-12.05,3.11-14.28,11a32.19,32.19,0,0,0-.53,14.7c1.38,7.56,5.13,10.95,12.7,11.67,6.31.61,12.6.05,18.88-.64,1.12-.13,1.33-.54,1.32-1.55C243.37,52.42,243.4,47.11,243.4,41.8Z'
                fill='#6b6c70' />
            <path
                d='M0,32.05Q0,16.78,0,1.52C0,.53.26.2,1.26.23A20.62,20.62,0,0,0,4.49,0c.88-.11,1.15.14,1.14,1.07,0,5.47,0,10.94,0,16.4,0,1.07.18,1.34,1.32,1.13,5.57-1,11.17-2,16.87-1.83a22.11,22.11,0,0,1,11.94,3.34c4.19,2.75,6.58,6.79,7.85,11.5A38.88,38.88,0,0,1,43.69,52C41.54,60.08,36.33,64.34,28,65.42c-5.32.69-10.61.23-15.9-.14C8.53,65,5,64.51,1.39,64.19.35,64.1,0,63.8,0,62.69.05,52.48,0,42.26,0,32.05Zm5.58,9.68V58c0,.54-.14,1.06.75,1.17a96,96,0,0,0,20.19.68c6.13-.57,10.41-4.1,11.83-10a33.5,33.5,0,0,0,0-15.77c-1-4.15-3.1-7.61-7-9.67a16.84,16.84,0,0,0-7.61-1.87A90.14,90.14,0,0,0,6.48,24.22c-.83.15-.88.57-.88,1.23Q5.62,33.6,5.61,41.73Z'
                fill='#6b6c70' />
            <path
                d='M286.75,65.75c-11.1,0-21.33-8-24-19.3-2.36-9.85,0-18.55,7.14-25.86.28-.29.58-.56.88-.82,1.55-1.33,3.15-1.34,4.36-.05a3.16,3.16,0,0,1-.43,4.57,21.2,21.2,0,0,0-3.74,4.34c-6.91,10.69-2.36,25.26,9.31,29.81a18.72,18.72,0,0,0,23.19-9,20.41,20.41,0,0,0-4.28-24.94,3.48,3.48,0,0,1-1.43-3.23,2.94,2.94,0,0,1,1.95-2.38,2.76,2.76,0,0,1,3,.68A26.66,26.66,0,0,1,297.92,63,23.45,23.45,0,0,1,286.75,65.75Z'
                fill='#6b6c70' />
            <path
                d='M147.41,41.66c0-7.31,0-14.61,0-21.91,0-.9.23-1.2,1.17-1.32a185.27,185.27,0,0,1,22.08-1.64,30.47,30.47,0,0,1,8.17,1.09c7.19,1.94,11,7,12.62,13.94a37.29,37.29,0,0,1,.88,8.55c0,7.71,0,15.41,0,23.11,0,1-.25,1.27-1.2,1.19a20.18,20.18,0,0,0-3.11,0c-1.06.09-1.41-.19-1.4-1.34.05-7.58,0-15.17,0-22.75a30.82,30.82,0,0,0-.79-7.6c-1.52-6.19-5.62-9.56-12.3-10.21-6.13-.6-12.21,0-18.29.63-2.23.24-2.22.31-2.22,2.6q0,18.68,0,37.36c0,1-.22,1.44-1.3,1.32a25.39,25.39,0,0,0-2.76,0c-1.81,0-1.62.25-1.62-1.56Q147.4,52.38,147.41,41.66Z'
                fill='#6b6c70' />
            <path
                d='M56.88,42.23c0-7.07,0-14.14,0-21.2,0-.71,0-1.16.93-1.31A141.53,141.53,0,0,1,78,17.46c.92,0,1.28.18,1.21,1.16a26.78,26.78,0,0,0,0,3.47c0,.75-.22.93-1,.94a110.62,110.62,0,0,0-14.53,1.4c-1,.14-1.28.52-1.28,1.52,0,12.45,0,24.91,0,37.37,0,1.12-.32,1.45-1.4,1.35a19.12,19.12,0,0,0-3.12,0c-.9.07-1.13-.23-1.13-1.12,0-6.71,0-13.42,0-20.13Z'
                fill='#6b6c70' />
            <path
                d='M289.74,23.69c0,4.34,0,8.69,0,13a3,3,0,0,1-3.22,3.18,3,3,0,0,1-2.69-3.14c0-1.88,0-3.75,0-5.63V11.52c0-.32,0-.64,0-.95a3,3,0,1,1,5.91.08C289.76,15,289.74,19.34,289.74,23.69Z'
                fill='#f78f44' />
        </svg>
        </LocalizedLink>
    )
}

export default Logo
