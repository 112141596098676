import React from "react"
import useTranslations from "../useTranslations"

import { useMenu } from "../../hooks/menu"
import Logo from '../Logo'
import Navigation from './Navigation'
import Languages from './Languages'
import MenuMobile from './MenuMobile'

const Header = () => {
    const { home } = useTranslations()
    const { openedMenu, toggleMenu } = useMenu()

    return (
        <header className="sticky top-0 z-50 lg:z-50 mx-auto bg-header-bg text-header-text">
            <div className="container px-2 mx-auto max-w-4xl flex-none flex justify-between items-center py-4">
                <Logo />
                <Navigation handleToggleMenu={toggleMenu} isActive={openedMenu}/>
                <div className="flex">
                    <Languages/>
                    <MenuMobile handleClick={toggleMenu} isActive={openedMenu}/>
                </div>
            </div>
        </header>
    )
}

export default Header
