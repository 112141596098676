import React, { useEffect, useState } from 'react'


const BackToTopIcon = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let ticking = false
        let myfunc = 0
        const updateScrollDir = () => {

            if(window.pageYOffset / window.innerHeight > 0.5) {
                setShow(true)
                window.clearTimeout(myfunc)
                myfunc = window.setTimeout(() => setShow(false), 3000)
            } else {
                setShow(false)
            }
            ticking = false
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir)
                ticking = true
            }
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    return (
        <a href='#' className={`fixed bottom-3 left-3 z-30 transition-opacity duration-500 ${!show?'opacity-0':''}`}>
            <svg className='w-11 h-11'
                 xmlns='http://www.w3.org/2000/svg'
                 viewBox='20 20 160 160' role='img'>
                <path fill='#C6A47E'
                      d='M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80z'
                ></path>
                <path fill='#FFF'
                      d='M66.066 116L62 111.64 100.151 74 138 111.668 133.953 116l-33.825-32.3L66.066 116z'
                ></path>
            </svg>
        </a>)

}

export default BackToTopIcon