import React, { useEffect, useState } from 'react'
import useMenu from '../../useMenu'
import LocalizedLink from '../../LocalizedLink'
import Link from 'gatsby-link'

const Navigation = ({ isActive, handleToggleMenu }) => {
    const menuItems = useMenu()

    const homeHash = typeof window !== `undefined` ? (window.location.pathname + (window.location.hash || '#home')) : ''

    return (
        <>
            <nav className={`hidden sm:block uppercase`}>
                {menuItems.map((menu, index) => {
                        return (!menu.outside &&
                            (<Link
                                className={`${homeHash === menu.link ? 'text-header-active' : ''} cursor-pointer px-1 md:px-2 hover:text-white lg:hover:text-header-active text-sm font-bold border-none`}
                                to={menu.link}
                                aria-label={menu.name}
                                key={`${menu.link}${index}`}
                            >{menu.name}</Link>)
                            ||
                            (<LocalizedLink
                                className={`cursor-pointer px-1 md:px-2 hover:text-white lg:hover:text-header-active text-sm font-bold border-none`}
                                to={menu.link}
                                aria-label={menu.name}
                                activeClassName="text-header-active"
                                key={`${menu.link}${index}`}
                            >{menu.name}</LocalizedLink>)
                        )
                    },
                )}
            </nav>
        </>
    )
}

export default Navigation
