import React from 'react'
import { Link, navigate } from 'gatsby'
import { useLocale } from '../../../hooks/locale'
import useLanguageMapping from '../../useLanguageMapping'
import EarthIcon from './earth-icon'

const Languages = () => {
    // Grab the locale (passed through context) from the Locale Provider
    // through useLocale() hook
    const { locale } = useLocale()

    const languageMapping = useLanguageMapping()

    function handleClickLanguage(e, lang) {
        e.preventDefault()
        if (locale === lang) return

        const url = window.location.pathname.split('/').pop()
        console.log(url)
        if (!url) return lang === 'en' ?
            navigate(`/`) :
            navigate(`/${lang}`)

        const associatedUrls = languageMapping.find(item => {
            let hasUrl = false

            Object.entries(item).forEach(([key, value]) => {
                if (value.split('/').pop() === url) return hasUrl = true
            })

            return hasUrl
        })

        if (!associatedUrls) return navigate('/')

        return lang === 'en' ?
            navigate(`/${associatedUrls[lang]}`) :
            navigate(`/${lang}/${associatedUrls[lang]}`)
    }

    return (
        <div className='items-center flex'>
            <EarthIcon />
            <div className='group'>
                <button
                    className='outline-none focus:outline-none border-none px-3 py-1 bg-transparent flex items-center min-w-20'
                >
                    <span className='pr-1 font-semibold flex-1 uppercase'>{locale}</span>
                    <span>
                          <svg
                              className='fill-current h-4 w-4 transform group-hover:-rotate-180
                            transition duration-150 ease-in-out'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                          ><path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                          </svg>
                    </span>
                </button>
                <ul
                    className='bg-header-bg border-header-bg rounded transform scale-0 group-hover:scale-100 absolute transition
                    duration-150 ease-in-out origin-top min-w-20 text-center'
                >
                    <li key="lang-zh" className={`uppercase rounded-sm py-2 ${locale === 'zh' ? 'hidden' : ''}`}>
                        <Link
                            to='/'
                            onClick={(e) => handleClickLanguage(e, 'zh')}
                            className={`px-3 block hover:text-header-active`}
                        >
                            zh
                        </Link>
                    </li>
                    <li key="lang-en" className={`uppercase rounded-sm py-2 ${locale === 'en' ? 'hidden' : ''}`}>
                        <Link
                            to='/'
                            onClick={(e) => handleClickLanguage(e, 'en')}
                            className={`px-3 block hover:text-header-active`}
                        >
                            en
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Languages
